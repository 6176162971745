import {
  addErrorHandler,
  AppError,
  getAppStatus,
  LOAD_ERROR,
  LOADING_SOURCE_CODE,
  SKIP_BECAUSE_BROKEN,
  registerApplication,
  start,
  unloadApplication,
} from "single-spa";
import { constructApplications, constructLayoutEngine, constructRoutes } from "single-spa-layout";

const routes = constructRoutes(document.querySelector("#single-spa-layout"));
let errorMessage = ``;
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
constructLayoutEngine({ routes, applications });
applications.forEach(registerApplication);

start({ urlRerouteOnly: true });

addErrorHandler((err: AppError) => {
  const appName = err.appOrParcelName;
  const status = getAppStatus(appName);
  if (status === LOAD_ERROR || status === SKIP_BECAUSE_BROKEN) {
    errorMessage = `An error occurred while loading ${appName}. Please contact Gentrack for assistance.
    ${err.message}`;
    System.delete(System.resolve(appName));
  } else if (status === LOADING_SOURCE_CODE) {
    errorMessage = `The service you are looking for is not available. Please contact Gentrack for assistance.
    ${err.message}`;
    System.delete(System.resolve(appName));
  } else {
    errorMessage = `Fatal Error - An error occurred while loading ${appName}. Please contact Gentrack for assistance.
    ${err.message}
    `;
    unloadApplication(appName);
  }
  const el = document.getElementById("micro-frontend-alert");
  el.innerHTML = `<p>${errorMessage}</p>`;
  el.style.display = "block";
  const wrapper = document
    .getElementsByClassName("micro-frontend-wrapper")[0]
    .getElementsByClassName("micro-frontend-content")[0];
  wrapper.appendChild(el);
});
